<template>
  <b-card
    title="Edit Role"
  >
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form
        ref="form"
        :style="{height: trHeight}"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            style="margin-bottom:-16px;"
          >
            <b-form-group
              label="Permissions"
            />
          </b-col>
        </b-row>
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <!-- Resources -->
          <b-col md="4">
            <b-form-group>
              <b-form-input
                ref="resource"
                type="text"
                placeholder="Resources"
                :value="item.resource"
              />
            </b-form-group>
          </b-col>

          <!-- Action -->
          <b-col md="2">
            <b-form-group>
              <b-form-select
                ref="action"
                :value="item.action"
                :options="options"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            style="margin-top: -21px;"
            lg="2"
            md="3"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="repeateAgain"
            >
              Add New Permission
            </b-button>
            <b-button
              variant="primary"
              class="mr-1"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name: 'roles' }"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormSelect,
    Loading,
  },
  directives: {
      Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: true,
      fullPage: false,
      name: '',
      order: 0,
      options: [
        { value: 'manage', text: 'Manage' },
        { value: 'read', text: 'Read' },
        { value: 'view', text: 'View' },
        { value: 'create', text: 'Create' },
        { value: 'add', text: 'Add' },
        { value: 'edit', text: 'Edit' },
        { value: 'update', text: 'Update' },
        { value: 'delete', text: 'Delete' },
      ],
      items: [],
      itemIndex: 0,
      permissions: [],
      required,
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  mounted() {
    // Init height
    this.initTrHeight()

    // Fetch role data from server
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/role/${this.$route.params.id}?[where][deletedAt]=null`)
      .then(role => {
        // Hide loading
        this.isLoading = false

        // Set the role name
        this.name = role.data.name

        // Loop against the permissions
        role.data.permissions.forEach((permission, index) => {
          // Push to items
          this.items.push({ id: index, resource: permission.subject, action: permission.action })

          // Add height to element
          this.$nextTick(() => {
            this.trAddHeight(this.$refs.row[0].offsetHeight)
          })
        })

        // Set the current length to item index
        this.itemIndex = this.items.length
      })
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      // Add new element
      this.items.push({
        id: this.itemIndex += 1,
      })

      // Add height to element
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    validationForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }

      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // Show loading
          this.isLoading = true

          // Check if user has access
          if (!this.$can('update', 'Role')) {
            this.isLoading = false
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Get all permission lists
          const permissions = []
          this.$refs.resource.forEach((element, index) => {
            const permission = {}
            permission.subject = element.$refs.input.value
            permission.action = this.$refs.action[index].$refs.input.value

            permissions.push(permission)
          })

          // Set the role data
          const data = {
            name: this.name,
            updatedAt: new Date(),
          }

          // Update role table
          await this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/role/${this.$route.params.id}`, data)

          // Delete previous permission data first
          await this.$http.delete(`${process.env.VUE_APP_API_BASE_URL}/api/permission/${this.$route.params.id}`, data)
          
          // Looping against permissions array
          permissions.forEach(async permission => {
            // Set the permission data
            const permissionData = {
              roleId: Number(this.$route.params.id),
              subject: permission.subject,
              action: permission.action,
            }

            // Save
            await this.$http.post(`${process.env.VUE_APP_API_BASE_URL}/api/permission`, permissionData)
          })

          // Redirect to role lists
          this.isLoading = false
          this.$router.replace({ name: 'roles' })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: 'Data has been updated successfully.',
                },
              })
            })
        }
      })
    },
  },
}
</script>